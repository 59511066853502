<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
      :customer-email="email"
    />
    <!-- <button class="" variant="primary" @click="submit">
      Pay
    </button> -->
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OneOffPayment",
  components: {
    StripeCheckout,
  },
  props: {
    email: {
      type: String,
    },
    // price: {
    //   type: String
    // },
    referrer: {
      type: String,
    },
    numberFamilyMembers: {
      type: Number,
    },
    priceOffered: {
      type: Number,
    },
  },
  data() {
    this.publishableKey =
      window.location.hostname == "localhost"
        ? "pk_test_51ImbPzDDiHmKYHSMzKOHlC99wIr2Qpan6u04EfgV8gYEetvK0qsTAKTrZHPWFmVCBf2UChkatu2AV4DlbVf5Nanv00vrsRprOs" /* Test */
        : "pk_live_51ImbPzDDiHmKYHSM1VUow7R3CP2MN3sBJa7GlNu7NjSGVzCTLFf8guy5gLI5bNssOEXIJeQk9PmL54Lo6BWf7I4q009QcySCLs"; /* Live */

    // let price = /* £20 */
    //   window.location.hostname == "localhost"
    //     ? "price_1L7z9uDDiHmKYHSMzYZ7TUCk" /* Test */
    //     : "price_1LAWrsDDiHmKYHSM2sQof24e"; /* Live */

    let price =
      /* £35 */
      window.location.hostname == "localhost"
        ? "price_1N7zAFDDiHmKYHSMa8mvhrEg" /* Test */
        : "price_1N5oeaDDiHmKYHSMzLiUxDdv"; /* Live */

    if (this.priceOffered == 245 ) {
      price =
        window.location.hostname == "localhost"
          ? "price_1NL0slDDiHmKYHSM5EwPV0Ky" /* Test */
          : "price_1NL0sqDDiHmKYHSMtJHGiuZE"; /* Live */
    }

    if (this.priceOffered == 295) {
      price =
        window.location.hostname == "localhost"
          ? "price_1NLPi5DDiHmKYHSM9i6suVN3" /* Test */
          : "price_1NLPjPDDiHmKYHSMDg1PtsIU"; /* Live */
    }

    //From this we disable dynamic pricing

    const setDynamicPricing = () => {
      if (this.priceOffered == 10) {
        // If Selects only one service of the below: £10
        price =
          window.location.hostname == "localhost"
            ? "price_1Lwnh6DDiHmKYHSM3A7eFac9" /* Test */
            : "price_1LwnhqDDiHmKYHSMZMUEEaOV"; /* Live */
      } else if (this.priceOffered == 5) {
        // If Selects only one service of the below (anything else): £5
        price =
          window.location.hostname == "localhost"
            ? "price_1LwniLDDiHmKYHSMV2eVUvz8" /* Test */
            : "price_1LwnirDDiHmKYHSMzgmvRvsd"; /* Live */
      } else {
        price =
          window.location.hostname == "localhost"
            ? "price_1L7z9uDDiHmKYHSMzYZ7TUCk" /* Test */
            : "price_1LAWrsDDiHmKYHSM2sQof24e"; /* Live */

        if (this.referrer === "MovingHub") price = "price_1LD7k4DDiHmKYHSM5xlQdUmc"; //Live
        // price = 'price_1LB1rnDDiHmKYHSMTVW9GqEl' //Test

        if (this.referrer === "Perx") price = "price_1LUWmKDDiHmKYHSM3kVwHb7f"; //Live
        // price ='price_1LUWjvDDiHmKYHSM6lgTJiZ4' //Test
      }
    };

    // setDynamicPricing() /* <- dynamic pricing DISABLED */

    return {
      loading: false,
      lineItems: [
        {
          // price:
          price,
          quantity: 1,
        },
      ],
      successURL: `${window.location.origin}/?confirm=${window.btoa(this.email)}`,
      cancelURL: `${window.location.origin}/?backStripe=${window.btoa(this.email)}`,
    };
  },
  mounted() {
    //SlothMove Family payment
    // console.log(this.numberFamilyMembers)
    if (this.numberFamilyMembers) {
      this.lineItems = [
        {
          price: "price_1LE9jhDDiHmKYHSMDSzjgOKu", //Test - Live
          //price: 'price_1LDrb9DDiHmKYHSMIzeEpDOs', //Test - family
          quantity: this.numberFamilyMembers - 1,
        },
      ];
    }
  },
  methods: {
    submit() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  computed: {
    ...mapState("formStore", [""]),
  },
  // watch: {
  //   priceOffered(newVal) {
  //     console.log(newVal);
  //   },
  // },
};
</script>

<style></style>
